
import { IonButton, IonContent, IonPage, IonSelect, IonLabel, IonSelectOption} from '@ionic/vue';
import { defineComponent, watch, ref, onUnmounted, onMounted } from 'vue';
import { useRouter } from 'vue-router';

import RouteCanvas from '@/components/wall-image-viewer/RouteCanvas.vue';
import { usePhotoGallery } from '@/composables/usePhotoGallery';
import { useCanvasWidth } from '@/composables/useCanvasWidth';
import getWalls, {Wall} from "@/common/api/route/getWalls";

export default defineComponent({
  name: 'NewRoute',
  components: {
    IonButton,
    IonContent,
    IonPage,
    RouteCanvas,
    IonSelect,
    IonLabel,
    IonSelectOption,
  },
  setup() {
    const router = useRouter();
    const wallSelected = ref(false);
    const wallList = ref<Array<Wall>>([]);
    const selectedWall = ref<Wall>();
    const { photo, takePhoto } = usePhotoGallery();
    const { canvasWidth, updateCanvasWidth } = useCanvasWidth();
    const ionContent = ref<typeof IonContent | null>(null);

    watch(selectedWall, async (newWall, oldWall) => {
      if (oldWall !== newWall && newWall) {
        wallSelected.value = true;
        updateCanvasWidth();
      }
    });

    window.addEventListener('resize', updateCanvasWidth);

    onMounted(() => {
      updateCanvasWidth();
    });

    onUnmounted(() => {
      window.removeEventListener('resize', updateCanvasWidth);
    });

    const handleClickUserGuide = () => {
      router.push({ name: 'Help', hash: '#section2' });
    };
    
    getWalls().then(value => wallList.value = value);

    return {
      canvasWidth,
      wallList,
      selectedWall,
      wallSelected,
      photo,
      takePhoto,
      ionContent,
      handleClickUserGuide,
    };
  },
});


import Konva from 'konva';
import {
  IonButton,
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonContent,
  IonIcon,
  IonItem,
  IonText,
  IonRow,
  IonCol,
  IonSpinner,
} from '@ionic/vue';
import { defineComponent, inject, onMounted, ref, watch, PropType } from 'vue';
import { useRouter } from 'vue-router';
import { caretForwardOutline, caretBackOutline } from 'ionicons/icons';

import { useBoxLayer, DrawLayer } from '@/components/wall-image-viewer/box-layer';
import { downloadURI } from '@/common/download';
import { SelectMode, TapeMode, NumberMode } from '@/components/wall-image-viewer/types';
import {
  addKonvaListenerPinchZoom,
  addKonvaListenerTouchMove,
  offKonvaStageListeners,
} from '@/components/wall-image-viewer/stageListeners';
import {Wall} from "@/common/api/route/getWalls";

Konva.pixelRatio = 1;

export default defineComponent({
  name: 'RouteCanvas',
  components: {
    IonButton,
    IonLabel,
    IonSegment,
    IonSegmentButton,
    IonIcon,
    IonItem,
    IonText,
    IonRow,
    IonCol,
    IonSpinner,
  },
  props: {
    wall: {
      type: Object as PropType<Wall>,
      required: true,
    },
    width: {
      type: Number,
      required: true,
    },
    ionContent: {
      type: Object as PropType<typeof IonContent>,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();
    const selectedMode = ref<SelectMode>(SelectMode.DRAWBOX);
    const tapeMode = ref<TapeMode>(TapeMode.NONE);
    const numberMode = ref<NumberMode>(NumberMode.ON);
    const hideNumbersText = ref<string>('Hide Numbers');
    const tapeText = ref<string>('No Tape');
    const isLoading = ref(false);
    const routeMakingStep = ref(0);

    const computeDimensions = (heightToWidthRatio: number) => {
      const windowHeight =
          window.innerHeight && document.documentElement.clientHeight
              ? Math.min(window.innerHeight, document.documentElement.clientHeight)
              : window.innerHeight ||
              document.documentElement.clientHeight ||
              document.getElementsByTagName('body')[0].clientHeight;
      const height = Math.min(props.width * heightToWidthRatio, windowHeight * 0.95);
      const width = height * (1 / heightToWidthRatio);
      return { width, height };
    };

    const setRouteImageUri: (imageUri: string) => void = inject(
        'setRouteImageUri',
        () => undefined,
    );

    let stage: Konva.Stage;
    const imageLayer = new Konva.Layer();
    const konvaImage = new Konva.Image({ image: undefined });
    const {
      boxLayer,
      resizeBoxLayer,
      addBoxLayerBoundingBoxes,
      clearBoxLayer,
      resetBoxLayerToUnSelected,
    } = useBoxLayer(selectedMode, tapeMode, numberMode);

    /**
     * Loads the Image and the bounding boxes retrieved from backend.
     */
    const loadImageOnStage = async () => {
      const image = new Image();
      image.crossOrigin = 'Anonymous';
      // eslint-disable-next-line
      image.addEventListener('load', async function () {
        // Clear all boxes first
        clearBoxLayer();
        // Add image to stage
        const { width, height } = computeDimensions(image.height / image.width);
        stage.width(width);
        stage.height(height);
        konvaImage.setAttrs({ image, width, height });
        const imageWidth = this.width;
        
        addBoxLayerBoundingBoxes(props.wall.boxList);
        
        resizeBoxLayer(width / imageWidth);
        imageLayer.batchDraw();

        // Listeners must be added after image is loaded
        // Currently initial listeners are for SelectMode.DRAWBOX
        addKonvaListenerPinchZoom(stage);
        DrawLayer.addKonvaDrawLayer(stage);
      });
      
      image.src = props.wall.imageURL;
    };

    onMounted(async () => {
      stage = new Konva.Stage({
        container: 'konva-container',
      });
      imageLayer.listening(false);
      imageLayer.add(konvaImage);
      stage.add(imageLayer);
      stage.add(boxLayer);

      await loadImageOnStage();
    });

    /**
     * Changing to drawing mode -> Draw layer is added
     * Changing away from drawing mode -> Boxes are exported from Draw layer into BoxLayer
     */
    const changeSelectMode = (newSelectedMode: SelectMode) => {
      const oldSelectedMode = selectedMode.value;
      if (+newSelectedMode === SelectMode.DRAWBOX) {
        offKonvaStageListeners(stage);
        addKonvaListenerPinchZoom(stage);
        DrawLayer.addKonvaDrawLayer(stage);
      } else if (+oldSelectedMode === SelectMode.DRAWBOX) {
        const newBoundingBoxes = DrawLayer.getKonvaDrawLayerBoundingBoxes(stage);
        DrawLayer.removeKonvaDrawLayer(stage);
        addKonvaListenerTouchMove(stage, props.ionContent);
        addBoxLayerBoundingBoxes(newBoundingBoxes);
        imageLayer.batchDraw();
      }
      selectedMode.value = newSelectedMode;
    };

    const resizeStage = () => {
      const { width: newWidth, height: newHeight } = computeDimensions(
          konvaImage.height() / konvaImage.width(),
      );
      const factor = newWidth / konvaImage.width();
      konvaImage.width(newWidth);
      konvaImage.height(newHeight);
      imageLayer.batchDraw();
      stage.width(newWidth);
      stage.height(newHeight);
      resizeBoxLayer(factor);
      if (DrawLayer.isDrawLayerAdded(stage)) {
        DrawLayer.resizeDrawLayer(stage, factor);
      }
    };
    watch(() => props.width, resizeStage);

    const handleHideNumbersClick = () => {
      if (numberMode.value === NumberMode.ON) {
        hideNumbersText.value = 'Unhide Numbers';
        numberMode.value = NumberMode.OFF;
      } else {
        hideNumbersText.value = 'Hide Numbers';
        numberMode.value = NumberMode.ON;
      }
    };

    const handleReset = () => {
      selectedMode.value = SelectMode.HANDHOLD;
      resetBoxLayerToUnSelected();
    };

    const handleExportClick = async () => {
      const imageUri = stage.toDataURL({ mimeType: 'image/jpeg', pixelRatio: 4 });
      await downloadURI(imageUri, 'Route.jpg');
    };

    const handlePostClick = () => {
      //reset the stage, otherwise zoomed stage results in clipped content
      stage.scaleX(1);
      stage.scaleY(1);
      stage.position({x:0,y:0});
      stage.batchDraw();
      
      const imageUri = stage.toDataURL({ mimeType: 'image/jpeg', pixelRatio: 4 });
      setRouteImageUri(imageUri);
      router.push({ name: 'UploadRoute', params: { gym: props.wall.gymId } });
    };

    const handleTapeClick = () => {
      if (tapeText.value === 'No Tape') {
        tapeText.value = '1-Hold Start';
        tapeMode.value = TapeMode.SINGLE_START;
      } else if (tapeText.value === '1-Hold Start') {
        tapeText.value = '2-Hold Start';
        tapeMode.value = TapeMode.DUAL_START;
      } else {
        tapeText.value = 'No Tape';
        tapeMode.value = TapeMode.NONE;
      }
    };

    const handleUndoDraw = () => {
      if (DrawLayer.isDrawLayerAdded(stage)) {
        DrawLayer.removeKonvaLastDrawnRect(stage);
      }
    };

    const handleScrollTop = () => {
      props.ionContent.$el.scrollToTop(500);
    };

    watch(
        () => props.wall,
        async () => {
          changeRouteMakingStep(0);
          await loadImageOnStage();
        },
    );

    /**
     * Depending on the step of the route making process, the canvas mode is switched accordingly
     */
    const changeRouteMakingStep = (step: number) => {
      if (step > 4 || step < 0) return;
      routeMakingStep.value = step;

      switch (routeMakingStep.value) {
        case 0:
          changeSelectMode(SelectMode.DRAWBOX);
          break;
        case 1:
          changeSelectMode(SelectMode.HANDHOLD);
          break;
        case 2:
          changeSelectMode(SelectMode.MARKDONE);
          break;
        case 3:
          changeSelectMode(SelectMode.MARKDONE);
          break;
        case 4:
          changeSelectMode(SelectMode.MARKDONE);
          break;
      }
    };

    return {
      SelectMode,
      selectedMode,
      hideNumbersText,
      handleHideNumbersClick,
      tapeText,
      handleTapeClick,
      handleExportClick,
      handlePostClick,
      handleUndoDraw,
      handleScrollTop,
      handleReset,
      routeMakingStep,
      caretForwardOutline,
      caretBackOutline,
      changeRouteMakingStep,
      changeSelectMode,
      isLoading,
    };
  },
});

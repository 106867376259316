import Providers from "@/providers";
import axios from "axios";
import cacheManager from "cache-manager";
import {Box} from "@/components/wall-image-viewer/getBoundingBoxes";
import {checkAgainstUnauthorizedResponse, flatOutWall} from "@/common/utils";

const getWallUrl = process.env.VUE_APP_ROUTE_ENDPOINT_URL + 'v2/walls';

const memoryCache = cacheManager.caching({
    store: 'memory',
    max: 10, // Number of items in cache
    ttl: 1, // Seconds
});

const getWalls = async (): Promise<Wall[]> => {
    const headers = {
        Authorization: `Bearer ${Providers.getAccessToken().value}`,
        accept: 'application/json'
    }
    
    try {
        const response = await axios.get(getWallUrl, { headers });
        return Array.from(response.data).map(flatOutWall);
    } catch (error:any) {
        checkAgainstUnauthorizedResponse(error);
        console.error(error);
    }
    
    return [];
};

// Caches in-memory, disappears on page refresh
const getWallsCached = async (
): Promise<Wall[]> => {
    // createdAt attribute only should be sufficient for 1 sec cache
    return memoryCache.wrap('blub', function () {
        return getWalls();
    });
};

interface Wall {
    id:number;
    name: string;
    gymId: number;
    imageURL: string;
    boxList:Array<Box>
}

export default getWallsCached;
export { Wall };